import React from 'react';
import { Helmet } from "react-helmet"

import Footer from './Footer';
import Header from './Header';

const Layout = ({ children }) => {

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Evren | Software Solutions</title>
        <link rel="canonical" href="http://evren.co.uk" />
      </Helmet>
      <Header />
      <main className="text-gray-900">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
